<template>
  <div class="w900">
     <h2 class="subBanner"><img src="@/assets/img/icon_gdia.svg">{{$t('front.common.notice')}}</h2>
     <div class="boardWrap">
       <board-filter @onSearch="onSearch"/>
       <div class="board">
         <ul class="boardw first">
           <li class="w150">{{$t('front.board.type')}}</li>
           <li class="flex-center">{{$t('front.board.title')}}</li>
           <li class="w150">작성자</li>
           <li class="w150">날짜</li>
         </ul>

         <template v-for="item in list" v-bind:key="item.boardIdx">
           <ul class="boardw">
             <li class="w150 bl-impor">{{$t('front.boardCategory.' + item.category)}}</li>
             <li class="textleft">
               <a href="#" @click.prevent="onClickRead(item.boardIdx)">
                 {{item.title}}
                 <span class="nsicon rdbg ml5">N</span>
               </a>
             </li>
             <li class="w150">{{ item.memName }}</li>
             <li class="w150">{{dateFormatAll(item.regDate)}}</li>
           </ul>
         </template>
   <!--

         <ul class="boardw">
           <li class="w150">스포츠북</li>
           <li class="textleft"><a href="#" @click="onClickRead()">야구스포츠 스페셜 규정</a></li>
         </ul>
         <ul class="boardw">
           <li class="w150">기타</li>
           <li class="textleft"><a href="#" @click="onClickRead()">은행별 점검시간안내</a></li>
         </ul>
         <ul class="boardw">
           <li class="w150">미니게임</li>
           <li class="textleft"><a href="cscenterread.html">게임 관련 규정</a></li>
         </ul>
         <ul class="boardw">
           <li class="w150">카지노</li>
           <li class="textleft"><a href="cscenterread.html">에볼루션카지노 이용안내</a></li>
         </ul>-->
       </div>

       <div class="mt20">
          <pagination
              :pageNum="pageInfo.page"
              :pageSize="pageInfo.count_per_list"
              :totalCount="pageInfo.tatal_list_count"
              @goToPage="loadList()"
          />
       </div>
     </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'

export default {
  name: 'Notice',
  components: {
    Pagination,
    BoardFilter
  },
  data () {
    return {
      list: null,
      boardType: 'nt',
      searchParam: {
        searchOption: '',
        searchValue: ''
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList () {
      const params = {
        boardType: this.boardType
      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      console.log(params)

      const result = await this.getBoardList(params, this.pageInfo.page)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'noticeRead', params: { boardIdx: id } })
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
